











































































































































































import {Component, Vue} from "vue-property-decorator";
import {
  BButton,
  BCard,
  BContainer,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BNav,
  BNavItem,
  BOverlay,
  BSpinner, BFormTextarea
} from "bootstrap-vue";
import $store from "@/store";

@Component({
  components: {
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class Survey extends Vue {
  loading = false;
  overrideEmailContent = false;

  contentOverride: RegistrationsAPI.Surveys.ContentOverride = {
    title: "",
    subtitle: "",
    content: "",
    ctaText: "",
    logo: "http://cdn.mcauto-images-production.sendgrid.net/2c7c6c298503a3dc/2dd98c92-2536-4c64-ba4a-f34b2d67cf98/1004x276.png",
    colour: "#00b2a9"
  };

  item: RegistrationsAPI.Surveys.Item = null;
  invites: RegistrationsAPI.Surveys.SurveyInvite[] = [{
    firstName: "",
    lastName: "",
    email: ""
  }]

  async mounted() {
    await this.getSurvey();
  }

  get id() {
    return this.$route.params.identifier;
  }

  async getSurvey() {
    this.item = await this.$api.registrations.surveys.Get(this.id);
    this.$store.commit("base/SET_TITLE", this.item.name);
  }

  addInvite() {
    this.invites.push({
      firstName: "",
      lastName: "",
      email: ""
    })
  }

  removeInvite(index: number) {
    this.invites.splice(index, 1);
  }

  async sendInvites() {
    this.loading = true;
    const send = await this.$api.registrations.surveys.Send(this.id, this.invites, this.overrideEmailContent ? this.contentOverride : null);
    if (send) {
      this.$bvToast.toast("Invites sent", {
        title: "Success",
        variant: "success",
        solid: true
      });
    }

    this.loading = false;

  }
}
